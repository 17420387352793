import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./grid-image.css";

const GridImage = ({ col, row, image, imageAlt }) => {
  return (
    <div
      className={"grid-image-wrapper"}
      style={{
        gridColumn: `span ${col}`,
        gridRow: `span ${row}`,
      }}
    >
      <div className={"grid-image-background"}>
        <GatsbyImage
          image={image}
          alt={imageAlt}
          className={"grid-image-image"}
        />
      </div>
    </div>
  );
};

export { GridImage };
