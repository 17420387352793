import * as React from "react";
import { SEO } from "../components/seo";
import { Layout } from "../components/layout";
import { GridImage } from "../components/grid-image";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./images.css";

const Images = () => {
  const data = useStaticQuery(graphql`
    {
      image0: file(
        relativePath: { eq: "andrea-lopez-images-0.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image1: file(
        relativePath: { eq: "andrea-lopez-images-1.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image2: file(
        relativePath: { eq: "andrea-lopez-images-2.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image3: file(
        relativePath: { eq: "andrea-lopez-images-3.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image4: file(
        relativePath: { eq: "andrea-lopez-images-4.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image5: file(
        relativePath: { eq: "andrea-lopez-images-5.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image6: file(
        relativePath: { eq: "andrea-lopez-images-6.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image7: file(
        relativePath: { eq: "andrea-lopez-images-7.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image8: file(
        relativePath: { eq: "andrea-lopez-images-8.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image9: file(
        relativePath: { eq: "andrea-lopez-images-9.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image10: file(
        relativePath: { eq: "andrea-lopez-images-10.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image11: file(
        relativePath: { eq: "andrea-lopez-images-11.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image12: file(
        relativePath: { eq: "andrea-lopez-images-12.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image13: file(
        relativePath: { eq: "andrea-lopez-images-13.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const image0 = getImage(data.image0);
  const image1 = getImage(data.image1);
  const image2 = getImage(data.image2);
  const image3 = getImage(data.image3);
  const image4 = getImage(data.image4);
  const image5 = getImage(data.image5);
  const image6 = getImage(data.image6);
  const image7 = getImage(data.image7);
  const image8 = getImage(data.image8);
  const image9 = getImage(data.image9);
  const image10 = getImage(data.image10);
  const image11 = getImage(data.image11);
  const image12 = getImage(data.image12);
  const image13 = getImage(data.image13);

  return (
    <Layout>
      <div className={"images-wrapper"}>
        <div className={"images-grid"}>
          <div className={"images-image-wrapper"}>
            <div className={"images-image-background"}>
              <GatsbyImage
                image={image0}
                alt={"image0"}
                className={"images-image"}
              />
            </div>
            <h1 className={"images-image-text"}>
              A
              <br />
              FEW
              <br />
              IMAGES
            </h1>
          </div>
          <GridImage col={1} row={1} image={image1} imageAlt={"image1"} />
          <GridImage col={1} row={2} image={image2} imageAlt={"image 2"} />
          <GridImage col={1} row={2} image={image3} imageAlt={"image 3alt"} />
          <GridImage col={1} row={1} image={image4} imageAlt={"image 4"} />
          <GridImage col={2} row={2} image={image5} imageAlt={"image 5"} />
          <GridImage col={1} row={1} image={image6} imageAlt={"image 6"} />
          <GridImage col={2} row={2} image={image7} imageAlt={"image 7"} />
          <GridImage col={1} row={1} image={image8} imageAlt={"imae 8"} />
          <GridImage col={1} row={2} image={image9} imageAlt={"image 9"} />
          <GridImage col={1} row={2} image={image10} imageAlt={"image 10"} />
          <GridImage col={1} row={1} image={image11} imageAlt={"image 11"} />
          <GridImage col={2} row={2} image={image12} imageAlt={"image 12"} />
          <GridImage col={1} row={1} image={image13} imageAlt={"image 13"} />
        </div>
      </div>
    </Layout>
  );
};

export default Images;

export const Head = () => <SEO title={"Images"} />;
